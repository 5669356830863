<script setup>
import CustomerSearch from "@/components/CustomerSearch.vue";
import { defineEmits, defineProps, ref } from "vue";
import dayjs from "dayjs";

var advancedFormat = require("dayjs/plugin/advancedFormat");
dayjs.extend(advancedFormat);

const emits = defineEmits(['changeCustomer']);
const props = defineProps({
  reference: { type: Object, required: true },
  editable: { type: Boolean, required: false, default: false },
});

const reference = ref(props.reference);

const changeCustomer = (customer) => {
  emits("changeCustomer", customer);
}
</script>
<template>
  <form-box header="General Information">
    <form class="grid grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5 gap-4 max-w-full">
      <div>
        <label>Status</label>
        <span>{{ reference.accepted_at ? "Accepted" : "Pending" }} </span>
      </div>
      <div>
        <label>Reference</label>
        <ReferenceNumber :reference="reference" />
      </div>
      <div>
        <label for="name">Job Name</label>
        <TextField :data="reference.name" :editable="editable" @update="(val) => (reference.name = val)" />
      </div>

      <div>
        <label>Customer</label>
        <span v-if="!editable" class="label-customer overflow-hidden"><router-link
            :to="'/customer/' + reference?.customer?.id">{{
              reference?.customer?.company
            }}</router-link></span>
        <CustomerSearch v-else :customer="reference.customer" :resetAfterSelection="false" @selected="changeCustomer" />
      </div>
      <div>
        <label>Invoice</label>
        <router-link v-if="reference?.invoice" :to="/invoice/ + reference?.invoice?.id" class="label-clickable"
          :class="{ 'bg-pink-500': reference.invoice?.paid_at, 'bg-rose-500': !reference.invoice?.paid_at }">
          <span v-if="!reference.invoice?.sent_at && !reference.invoice?.paid_at">Unsent</span>
          <span v-else-if="!reference.invoice?.paid_at">Unpaid</span>
          <span v-else>Paid</span>
        </router-link>
        <span v-else>n/a</span>
      </div>
    </form>
  </form-box>
</template>
