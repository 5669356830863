<script setup>
import { onMounted, ref, watchEffect } from "vue";
import { useRoute } from "vue-router";
import { useBreadcrumbStore } from "@/stores/BreadcrumbStore";
import ReferenceComments from "@/components/ReferenceComments";
import ReferenceHistory from "@/components/ReferenceHistory";
import ReferenceResources from "@/components/ReferenceResources";
import ReferenceLocation from "@/components/ReferenceLocation";
import { useReferenceStore } from "@/stores/ReferenceStore";
import ReferenceSummary from "@/components/ReferenceSummary";
import * as Icon from "@heroicons/vue/24/solid";

const route = useRoute();
const breadcrumbStore = useBreadcrumbStore()
const reference = useReferenceStore();
const editable = ref(false)
const history = ref(null)

// Setup
onMounted(() => {
  reference.configure(route.params.id)
  breadcrumbStore.setValue("")

})

// Set breadcrumb when loading complete
watchEffect(() => {
  if (!reference.isLoading) {
    breadcrumbStore.setValue(reference.name || ""); // Set breadcrumb dynamically
  }
});

// Enable editing
const edit = () => {
  editable.value = true;
};

// Save state
const save = async () => {
  editable.value = false;
  reference.update();
}

// Version has been accepted
const setVersionAccepted = () => {

  if (!confirm("Are you sure you want to accept this version?")) {
    return;
  }

  reference.acceptVersion()
  window.triggerConfetti();
};


</script>
<template>
  <div v-if="!reference.isLoading">
    <FormBox :header="reference.data.name">
      <template v-slot:nav>
        <button class="btn-plain bg-green-600" @click.prevent="setVersionAccepted" v-if="!editable">
          <Icon.SparklesIcon class="icon" /> <span class="hidden md:inline-block">Accept</span>
        </button>

        <EditButton @edit="edit" v-if="!editable" />
        <SaveButton @save="save" title="Save" v-if="editable" />
      </template>
    </FormBox>

    <ReferenceSummary :reference="reference.data" :editable="editable" />

    <ReferenceLocation v-if="false" />

    <ReferenceResources :reference="reference" :editable="editable" />

    <div class="grid grid-cols-1 xl:grid-cols-2 gap-6 mb-6">
      <FormBox header="Description">
        <textarea class="w-full min-h-36" v-model="reference.data.description" v-if="editable"></textarea>
        <span v-else>
          {{ reference.data.description ?? "Description not provided" }}
        </span>
      </FormBox>

      <FormBox header="Notes">
        <textarea class="w-full min-h-36" v-model="reference.data.notes" v-if="editable"></textarea>
        <span v-else class="whitespace-pre-line">
          {{ reference.data.notes ?? "Notes not provided" }}
        </span>
      </FormBox>
    </div>

    <div class="grid grid-cols-1 xl:grid-cols-2 gap-6">
      <ReferenceComments :reference_id="reference.data.id" />
      <ReferenceHistory ref="history" :reference_id="reference.data.id" />
    </div>
  </div>
  <div v-else>
    Loading..
  </div>
</template>
