import { ref } from 'vue'

export default function useDebouncedFunction(func, delay = 200) {
  let timeoutId = null
  let isInitial = ref(true)

  function debouncedFunction(...args) {
    // If this is the initial call, execute immediately
    if (isInitial.value) {
      func(...args)
      isInitial.value = false
    }

    // Clear the existing timeout, if there is one
    if (timeoutId) {
      clearTimeout(timeoutId)
    }

    // Set a new timeout
    timeoutId = setTimeout(() => {
      func(...args)
      // Reset the initial flag
      isInitial.value = true
    }, delay)
  }

  // Disable existing timer
  function disableTimer() {
    if (timeoutId) {
      clearTimeout(timeoutId)
    }
  }

  return { debouncedFunction, disableTimer }
}
