import apiClient from "@/apiClient";

class ProductService {

  async index() {
    try {
      const response = await apiClient.get("/api/product");
      return response.data
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
}
export default ProductService;
