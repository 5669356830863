<script setup>
import apiClient from '@/apiClient.js';
import { onMounted, ref } from 'vue';
import { useRouter } from "vue-router";

const router = useRouter();

// Data references
const locations = ref([]);
const center = ref({ lat: 53.2487471, lng: -3.0048407 }); // Default map center
const zoom = ref(7);

onMounted(async () => {
  try {
    const response = await apiClient.get('/api/staff/locations');
    const staffLocations = response.data;

    // Map staff locations to markers
    locations.value = staffLocations
      .filter((staff) => staff.first_address && staff.first_address.latitude && staff.first_address.longitude) // Ensure valid coordinates
      .map((staff) => ({
        position: {
          lat: parseFloat(staff.first_address.latitude),
          lng: parseFloat(staff.first_address.longitude)
        },
        staff_name: staff.staff_name,
        staff_id: staff.staff_id,
      }));
  } catch (error) {
    console.error('Failed to fetch staff locations:', error);
  }
});

const view = (id) => {
  router.push({
    name: "staff-view",
    params: { id: id },
  });
}
</script>

<template>
  <div class="map h-full">
    <GMapMap :center="center" :zoom="zoom" map-type-id="terrain" class="w-full h-full">
      <GMapMarker v-for="(location, index) in locations" :key="index" :position="location.position"
        :title="location.staff_name" @click="view(location.staff_id)" />
    </GMapMap>

  </div>
</template>

<style scoped>
.map {
  height: calc(100vh - 6em);
  /* Full viewport height */
  width: 100%;
  /* Full width */
  display: flex;
  flex-direction: column;
}

.map-container {
  flex-grow: 1;
  /* Allow the map to grow and fill the container */
  height: 100%;
  /* Ensures it uses the full height of the parent */
  width: 100%;
  /* Full width */
}
</style>
