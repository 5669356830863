import apiClient from "@/apiClient";

class UserAccountService {
  static async changePassword(userId, passwords) {
    try {
      return await apiClient.put(`/api/user/password`, passwords);
    } catch (error) {
      return error;
    }
  }
}

export default UserAccountService;

