<script setup>
import { ref } from "vue";
import UserAccountService from "@/services/UserAccountService";

const passwords = ref({ current: "", new: "", confirm: "" });
const theme = ref("default");
const userId = 1; // Replace with the actual logged-in user ID

const changePassword = async () => {
  try {
    let result = await UserAccountService.changePassword(userId, {
      current: passwords.value.current,
      new: passwords.value.new,
      new_confirmation: passwords.value.confirm
    });
    passwords.value = { current: "", new: "", confirm: "" };
  } catch (error) {
    alert("Error changing password. Please try again." + error);
  }
};
</script>

<template>
  <div>
    <FormBox header="Account Settings">
      <div class="grid grid-cols-1 md:grid-cols-2 gap-4 max-w-lg">
        <label>Current password</label>
        <input type="password" v-model="passwords.current" />
        <label>New password</label>
        <input type="password" v-model="passwords.new" />
        <label>Confirm password</label>
        <input type="password" v-model="passwords.confirm" />
        <span></span>
        <button class="btn" @click="changePassword">Change Password</button>
      </div>
    </FormBox>
    <FormBox header="Visual Settings" class="hidden">
      <div class="grid grid-cols-1 md:grid-cols-2 gap-4 max-w-lg">
        <label>Theme</label>
        <select v-model="theme">
          <option value="default">Default</option>
          <option value="light">Light</option>
          <option value="dark">Dark</option>
          <option value="lowcontrast">Low Contrast</option>
        </select>
      </div>
    </FormBox>
  </div>
</template>

