<script setup>
import { useListStoreNew } from "@/stores/ListStoreNew";
import { defineProps, onMounted, ref, } from "vue";
import dayjs from "dayjs";
import PrevNextButtons from "@/components/PrevNextButtons.vue";
import ListSearch from "@/components/ListSearch.vue";
import { useRoute, useRouter, onBeforeRouteUpdate } from "vue-router";
import { PlusCircleIcon } from "@heroicons/vue/24/solid";
import { MapIcon } from "@heroicons/vue/24/solid";
import { usePagination } from "@/composables/usePagination";
import { useBreadcrumbs } from "@/composables/useBreadcrumbs";
var relativeTime = require("dayjs/plugin/relativeTime");
dayjs.extend(relativeTime);

const list = useListStoreNew();
const filter = ref("");
const router = useRouter();
const route = useRoute();
const { setUrl, changePage } = usePagination(list, filter);
const { setBreadcrumb, clearBreadcrumb } = useBreadcrumbs();

onMounted(() => {
  list.configure({ remote: "/api/staff", local: "/staff", });
  filter.value = route.query?.af ?? "active" // default filter to apply to list
  applyFilter()
  list.fetchIndex()
});

const view = (val) => {
  if (event.shiftKey || event.ctrlKey || event.altKey || event.metaKey) {
    return; // Do nothing if any modifier key is held
  }

  router.push({
    name: "staff-view",
    params: { id: val.id },
  });
  setBreadcrumb(val.subject)
};

const setFilter = (val) => {
  filter.value = val;
  load();
};

const applyFilter = () => {
  list.clearFilters();
  if (filter.value == "active") {
    list.addFilter("active", "true");
  } else if (filter.value == "inactive") {
    list.addFilter("active", "false");
  }
}

const load = () => {
  applyFilter()
  setUrl();
  list.resetPage();
};

</script>
<template>
  <div>
    <div class="box">
      <div class="flex w-max-full lg:w-full space-between">
        <ListSearch class="flex-1 mr-4" url="/api/staff/search?query=" :output="(val) => {
          return val.contact.name + ' [' + val.contact.email + ']';
        }
          " @selected="view" />
        <div class="flex gap-2">
          <router-link to="/staff/create" class="btn accent" title="Create New">
            <PlusCircleIcon class="icon" />
          </router-link>
          <router-link to="/staff/map" class="btn accent" title="Staff Locations">
            <MapIcon class="icon" />
          </router-link>
        </div>
      </div>
      <div class="grid grid-cols-1 md:grid-cols-3 gap-2 mt-4">
        <button @click.prevent="() => {
          setFilter('all');
        }
          " class="btn-plain bg-sky-500">
          All
        </button>
        <button class="btn-plain bg-indigo-500" @click.prevent="() => {
          setFilter('active');
        }
          ">
          Active
        </button>
        <button class="btn-plain bg-teal-500" @click.prevent="() => {
          setFilter('inactive');
        }
          ">
          Inactive
        </button>
      </div>
    </div>
    <table class="box list" v-if="!list.isLoading">
      <tbody>
        <tr v-for="staff in list.getIndex" :key="staff"
          class="border-b border-gray-300 hover:bg-gray-100 dark:hover:bg-gray-900 cursor-pointer"
          @click.prevent="view(staff)">
          <td class="p-4 w-96 overflow-hidden hidden xl:table-cell text-center w-10 min-w-10">
            <label class="label-generic" v-show="staff.contact?.title">
              <span class="hidden lg:inline">{{ staff.contact?.title }}</span>
              <span class="lg:hidden">{{ String(staff.contact?.title).substring(0, 1) ?? '' }}</span>
            </label>
          </td>
          <td class="p-4 w-96">{{ staff.contact?.name }}</td>
          <td class="p-4 w-96 hidden md:table-cell">{{ staff.contact?.email }}</td>
          <td class="p-4 hidden lg:table-cell">{{ staff.contact?.phone }}</td>
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <td colspan="5" class="p-4" v-if="list.getIndex.length < 1">
            No data to display
          </td>
        </tr>
        <tr>
          <th colspan="5" class="p-4">
            <PrevNextButtons :showPrev="list.showPrev" :showNext="list.showNext" @prev="changePage(-1)"
              @next="changePage(1)" />
          </th>
        </tr>
      </tfoot>
    </table>

    <table class="box list" v-else>
      <tbody>
        <!-- Repeat this skeleton row as needed -->
        <tr v-for="n in 24" :key="n" class="border-b border-gray-300 animate-pulse">
          <td class="p-3 text-center w-10 lg:w-32 min-w-10">
            <div class="h-6 bg-gray-300 rounded"></div>
          </td>
          <td class="p-3 max-w-32 truncate hidden sm:table-cell">
            <div class="h-6 bg-gray-300 rounded"></div>
          </td>
          <td class="p-3 truncate max-w-36 lg:max-w-96">
            <div class="h-6 bg-gray-300 rounded"></div>
          </td>
          <td class="p-3 text-right hidden sm:table-cell">
            <div class="h-6 bg-gray-300 rounded"></div>
          </td>
          <td class="text-right w-4">
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
