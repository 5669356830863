<script setup>
import { computed, defineProps, onMounted, ref } from 'vue'
import DeleteButton from './DeleteButton.vue';
import ProductService from "@/services/ProductService";
import ProductPicker from "@/components/ProductPicker.vue"
import dayjs from "dayjs";

const props = defineProps({
  editable: { type: Boolean, required: false },
  reference: { type: Object, required: true }
})

const reference = ref(props.reference)
const products = ref({})

onMounted(() => {
  getProducts();
})

const getProducts = async () => {
  const productService = new ProductService();
  products.value = await productService.index()
}

const sortedDates = computed(() => {
  return [...reference.value.resources.dates].sort((a, b) => new Date(a.date) - new Date(b.date));
});

const searchStaffOutput = staff => staff.contact.name

// Assign staff to a given resource slot
const assignStaff = (resource, entry, staff) => {
  entry.staff_id = staff.id;
  entry.staff = staff;

  // Find resources in other dates with the same start and finish times
  sortedDates.value.forEach(dateEntry => {
    if (
      dateEntry.start.slice(0, 5) === resource.start.slice(0, 5) &&
      dateEntry.finish.slice(0, 5) === resource.finish.slice(0, 5)
    ) {
      let staffAllocated = false; // Track if staff has been allocated for this date

      // Ensure staff is not already allocated to this date
      dateEntry.resources.forEach(e => {
        if (e.staff_id === entry.staff_id) {
          staffAllocated = true;
        }
      });

      // Allocate if there is a blank slot and staff is not already allocated
      if (!staffAllocated) {
        for (const e of dateEntry.resources) {
          if (!e.staff_id) {
            e.staff_id = entry.staff_id;
            e.staff = entry.staff;
            staffAllocated = true; // Mark as allocated for this date
            break; // Stop after assigning to one slot
          }
        }
      }
    }
  });

};

// Structure used for adding new datetime resource slots
const dateTime = ref({
  startDate: (new Date().toISOString().split("T")[0]),
  finishDate: (new Date().toISOString().split("T")[0]),
  startTime: ("08:00"),
  finishTime: ("18:00"),
  qty: 2,
  product: 0,
})

// Add a range of dates, times and resources at once
const add = () => {
  const startDate = new Date(dateTime.value.startDate);
  const endDate = new Date(dateTime.value.finishDate);

  for (let date = new Date(startDate); date <= endDate; date.setDate(date.getDate() + 1)) {
    reference.value.addDateProduct(
      date.toISOString().split('T')[0],
      dateTime.value.startTime,
      dateTime.value.finishTime,
      products.value[dateTime.value.product],
      dateTime.value.qty
    )
  }
}

// Always make sure finish date range is more than or equal to the start date range
const changeStartDate = () => {
  const startDate = new Date(dateTime.value.startDate);
  const finishDate = new Date(dateTime.value.finishDate);

  if (finishDate < startDate) {
    dateTime.value.finishDate = dateTime.value.startDate; // Set finishDate to startDate
  }
};

const deleteDate = (index) => {
  reference.value.deleteDateIndex(index)
}

const updateProduct = (entry) => {
  // Find the selected product by id
  const selectedProduct = products.value.find(product => product.id === entry.product_id);

  // Update entry.product with the full product object
  entry.product = selectedProduct || null; // Fallback to null if no product found
};


</script>
<template>
  <FormBox header="Resources">

    <div class="mb-8 space-x-2" v-show="props.editable">
      <input type="date" v-model="dateTime.startDate" class="border p-2 rounded" @change="changeStartDate" />
      to
      <input type="date" v-model="dateTime.finishDate" class="border p-2 rounded" />
      <input type="time" v-model="dateTime.startTime" class="border p-2 rounded" />
      <input type="time" v-model="dateTime.finishTime" class="border p-2 rounded" />
      <select class="border p-2 rounded" v-model="dateTime.product">
        <option v-for="(product, index) in products" :key="index" :value="index">
          {{ product.name }}
        </option>
      </select>
      <input type="number" v-model="dateTime.qty" min="1" class="border p-2 rounded w-16" />
      <SaveButton title="Add" @click="add" />
    </div>

    <div class="grid grid-cols-1 lg:grid-cols-2 2xl:grid-cols-3 gap-8">
      <div v-for="(resource, i) in sortedDates" :key="resource.id || resource">
        <fieldset class="border p-2 h-full">
          <legend>
            <DeleteButton :disableTitle="true" v-if="editable" @click="deleteDate(i)" /> &nbsp;
            <DateField :data="resource.date" :editable="editable" @update="val => (resource.date = val)" />
          </legend>
          <fieldset class="p-2">
            <div>
              <TimeField :data="resource.start" :editable="editable" @update="val => (resource.start = val)" />
              to
              <TimeField :data="resource.finish" :editable="editable" @update="val => (resource.finish = val)" />
            </div>
            <hr class="my-2">
            <div v-for="entry in resource.resources" :key="entry">
              <div class="grid grid-cols-1 md:grid-cols-2 gap-x-4">
                <div v-if="!editable">{{ entry.product.name }}</div>
                <ProductPicker v-model="entry.product_id" @change="updateProduct(entry)" v-else />
                <div class="mb-4 md:mb-0 md:text-right" v-if="!editable">{{ entry.staff?.contact?.name ?? "Unstaffed" }}
                </div>
                <searchable-input v-else url="/api/staff/search?query=" :output="searchStaffOutput"
                  :placeholder="entry?.staff?.contact?.name ?? 'Search for ' + entry.product?.short"
                  @selected="(staff) => assignStaff(resource, entry, staff)" />
              </div>
            </div>
          </fieldset>
        </fieldset>
      </div>
    </div>

  </FormBox>

  <FormBox v-show="false">
    <div class="whitespace-pre bg-green-500 py-4" v-for="resource in reference.resources.dates" :key="resource">
      {{ resource }}
    </div>
  </FormBox>
</template>
