import { defineStore } from 'pinia';
import dayjs from 'dayjs';
import calendarService from '@/services/CalendarService';

export let useCalendarStore = defineStore('calendar', {
  state: () => ({
    date: new Date(),
    entries: [],
    error: null,
    refreshTimer: null, // Stores the interval ID for auto-refresh
  }),

  actions: {
    updateDateFromRoute(route) {
      const dateFromURL = route.query.date
        ? new Date(route.query.date)
        : new Date();
      this.setDate(dayjs(dateFromURL));
    },

    changeMonth(amount) {
      const newDate = dayjs(this.date).add(amount, 'month');
      this.setDate(newDate);
    },

    changeDay(amount) {
      const newDate = dayjs(this.date).add(amount, 'day');
      this.setDate(newDate);
    },

    async fetchCalendarData() {
      console.log('Updating calendar data...');
      try {
        const response = await calendarService.index(
          this.getYear,
          this.getMonth
        );
        this.entries = response;
      } catch (error) {
        this.error = error;
      }
    },

    setDate(date) {
      this.date = date;
      this.fetchCalendarData();
    },

    startAutoRefresh() {
      if (this.refreshTimer) return; // Avoid multiple timers
      this.fetchCalendarData(); // Initial fetch
      this.refreshTimer = setInterval(() => {
        this.fetchCalendarData();
      }, 60000); // Refresh every 60 seconds
    },

    stopAutoRefresh() {
      if (this.refreshTimer) {
        clearInterval(this.refreshTimer);
        this.refreshTimer = null;
      }
    },
  },

  getters: {
    getEntries: state => state.entries ?? [],

    getEntriesForDay: state => day => {
      const formattedDate = dayjs(day ?? state.date).format('YYYY-MM-DD');
      return state.entries.filter(entry => entry.date === formattedDate);
    },

    getDateRange: state => {
      const range = [];
      const firstDayOfMonth = dayjs(state.date).startOf('month');
      const firstDayOfWeek = firstDayOfMonth.day(); // Day of the week of the 1st
      const offset = firstDayOfWeek === 0 ? 6 : firstDayOfWeek - 1; // Align to Monday as start of week

      let date = firstDayOfMonth.subtract(offset, 'day'); // Start from the Monday of the previous week

      while (range.length < 42) {
        range.push(date);
        date = date.add(1, 'day');
      }
      return range;
    },


    getDate: state => state.date,
    getYear: state => dayjs(state.date).format('YYYY'),
    getMonth: state => dayjs(state.date).format('MM'),
    getError: state => state.error,
  },
});

