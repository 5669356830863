<script setup>
import { computed, ref, onMounted, onUnmounted } from "vue";

import { useRoute } from "vue-router";
import { useCalendarStore } from "@/stores/CalendarStore";
import CalendarHeader from "@/components/CalendarHeader.vue";
import dayjs from "dayjs";
import { CurrencyPoundIcon } from "@heroicons/vue/24/solid";

// Additional date formatting featur3es
var advancedFormat = require("dayjs/plugin/advancedFormat");
dayjs.extend(advancedFormat);

const calendarStore = useCalendarStore();
const route = useRoute();

onMounted(() => {
  calendarStore.startAutoRefresh();
});

onUnmounted(() => {
  calendarStore.stopAutoRefresh();
});

// Map events to dates and track slots
const eventsByDate = computed(() => {
  const eventsMap = {}; // Maps dates to their events
  const slotMap = new Map(); // Maps reference IDs to slots
  let slotCounter = 0; // Counter to track the slot position

  // Initialize the events map with empty arrays for each date in the range
  calendarStore.getDateRange.forEach((date) => {
    const formatted = dayjs(date).format("YYYY-MM-DD");
    eventsMap[formatted] = [];
  });

  // Iterate through each event and assign slots consistently
  calendarStore.getEntries.forEach((event) => {
    let slot;
    // Use the same slot for all events with the same reference ID
    if (slotMap.has(event.reference.id)) {
      slot = slotMap.get(event.reference.id);
    } else {
      slot = slotCounter++;
      slotMap.set(event.reference.id, slot);
    }

    // Assign each event to its corresponding date in the map
    event.dates.forEach((eventDate) => {
      const dateStr = dayjs(eventDate.date).format("YYYY-MM-DD");
      if (eventsMap[dateStr]) {
        eventsMap[dateStr].push({ ...event, slot });
      }
    });
  });

  // Sort events on each date by slot to maintain consistent row positions
  Object.keys(eventsMap).forEach((date) => {
    eventsMap[date] = eventsMap[date].sort((a, b) => a.slot - b.slot);
  });

  return eventsMap;
});


const isToday = (day) => {
  return dayjs(day).isSame(dayjs(), "day");
};
</script>

<template>
  <div class="grid grid-cols-7 grid-rows-[auto,repeat(6,minmax(0,1fr))] h-full">
    <CalendarHeader class="col-span-7 w-full min-h-0" view="month"></CalendarHeader>
    <div v-for="(day, index) in calendarStore.getDateRange" :key="day" :class="[
      'border w-full h-full overflow-none border-gray-200 dark:border-slate-600',
      isToday(day) ? 'bg-neutral-100 dark:bg-slate-600' : '',
      (dayjs(day).format('d') == 6 || dayjs(day).format('d') == 0) ? 'bg-gray-100 dark:bg-gray-700' : '',
      index === 0 ? 'rounded-tl-lg' : '',
      index === 6 ? 'rounded-tr-lg' : '',
      index === 35 ? 'rounded-bl-lg' : '',
      index === 41 ? 'rounded-br-lg' : '',
    ]">
      <div class="text-right h-full">
        <small class="px-1" :class="[isToday(day) ? 'font-bold accent rounded' : '']">
          <span
            v-if="index == 0 || dayjs(day).format('M') != dayjs(calendarStore.getDateRange[index - 1]).format('M')">{{
              dayjs(day).format("MMM") }}</span>
          {{ dayjs(day).format("Do") }}
        </small>

        <div v-for="event in eventsByDate[dayjs(day).format('YYYY-MM-DD')]" :key="event.id">
          <router-link :to="'/reference/' + event.reference.id">
            <div :class="[
              'text-left truncate w-2  inline-block m-0 py-0 rounded-none',
              event.reference.type.prefix === 'M1E' ? 'label-event' :
                event.reference.type.prefix === 'M1P' ? 'label-transport' :
                  event.reference.type.prefix === 'M1S' ? 'label-365' :
                    event.reference.type.prefix === 'M1T' ? 'label-training' :
                      'label-clikable bg-teal-500'
            ]" class="w-full shrink-0 flex gap-1 px-1 mb-1 text-sm hover:dark:bg-slate-900 hover:bg-gray-100">
              <CurrencyPoundIcon class="w-4 h-4 p-0 block shrink-0" v-show="event.payment_status === 1" />
              <div class="truncate">{{ event.reference.name }}</div>
            </div>
          </router-link>
        </div>

      </div>
    </div>
  </div>
</template>
