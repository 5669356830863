import apiClient from "@/apiClient";

class ReferenceService {
  id = null;

  constructor(id) {
    this.id = id;
  }

  async get() {
    try {
      return await apiClient.get("/api/reference/" + this.id);
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async update(data) {
    try {
      return await apiClient.put("/api/reference/" + this.id + "/manage", data);
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async acceptVersion(version_id) {
    apiClient.post("/api/reference/" + this.id + "/accepted", {
      version_id: version_id,
    });
  }
}
export default ReferenceService;
