<script setup>
import { defineModel, onMounted, ref } from 'vue'
import apiClient from '@/apiClient'
import { useProductStore } from "@/stores/ProductStore";


const productStore = useProductStore();
const model = defineModel()

onMounted(() => {
  productStore.fetchIndex()
})
</script>
<template>
  <select v-model="model" class="p-2">
    <option v-for="unit in productStore.all" :key="unit" :value="unit.id" v-text="unit.name" />
  </select>
</template>
