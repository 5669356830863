import { ref } from "vue";
import { defineStore } from 'pinia';
import ReferenceService from "@/services/ReferenceService";

export let useReferenceStore = defineStore('reference', {
  state() {
    return {
      referenceType: null,
      customerId: null,
      referenceId: null,
      referenceService: null,
      referenceData: ref({}),
      activeVersion: 0,
      loading: true,
    };
  },

  actions: {
    configure(referenceId) {
      this.referenceId = referenceId
      this.referenceService = new ReferenceService(this.referenceId);
      this.fetch();
    },

    setType(type) {
      this.referenceType = type;
    },

    acceptVersion() {
      const version = this.data.versions[this.activeVersion]
      this.referenceService.acceptVersion(version.id)
    },


    async fetch() {
      try {
        this.loading = true
        const data = await this.referenceService.get();
        this.referenceData.value = data;
        this.activeVersion = 0;
        this.loading = false
      } catch (error) {
        console.error(error);
      }
    },

    async update() {
      try {
        await this.referenceService.update(this.data)
      } catch (error) {
        console.log(error)
      }
    },

    addDateProduct(date, startTime, finishTime, product, qty = 0) {
      const resources = Array.from({ length: qty }, () => ({ product, product_id: product?.id ?? null }));

      console.log("times", startTime)

      // Find the existing date entry
      const dates = this.referenceData.value.data.versions[this.activeVersion].dates;
      const existingDate = dates.find(d =>
        new Date(d.date).toISOString().split('T')[0] === date &&
        d.start.slice(0, 5) === startTime &&
        d.finish.slice(0, 5) === finishTime
      );

      if (existingDate) {
        // If the entry exists, merge resources
        existingDate.resources.push(...resources);
      } else {
        // If the entry does not exist, create a new one
        dates.push({
          date: date,
          start: startTime,
          finish: finishTime,
          resources: resources,
        });
      }
    },


    deleteDateIndex(index) {
      this.referenceData.value.data.versions[this.activeVersion].dates.splice(index, 1);
    }
  },

  getters: {
    isLoading() {
      return this.loading
    },

    id() {
      return this.referenceId;
    },

    name() {
      return this.referenceData.value?.data.name;
    },

    data() {
      return this.referenceData.value.data;
    },

    resources() {
      return this.referenceData.value.data.versions[this.activeVersion]
    }

  }
});
