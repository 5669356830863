import { defineStore } from 'pinia';
import ProductService from "@/services/ProductService";
import { ref } from "vue";

export let useProductStore = defineStore('product', {
  state() {
    return {
      products: ref({}),
      productService: null,
      loading: false,
    };
  },

  actions: {
    load() {
      this.productService = new ProductService()
    },

    async fetchIndex() {

      if (this.loading || Object.keys(this.products).length) {
        return;
      }

      this.load()
      try {
        this.loading = true
        const index = await this.productService.index();
        this.products.value = index;
        console.log(index)
        this.loading = false
      } catch (error) {
        console.error(error);
      }
    },
  },

  getters: {
    all() {
      return this.products.value
    }
  }

});
