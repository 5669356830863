<script setup>
import { defineEmits } from 'vue'
import { CheckCircleIcon, XCircleIcon } from '@heroicons/vue/24/solid'

const props = defineProps({
  title: { default: 'Continue' },
  cancel: { default: 'Cancel' },
  hideCancel: { default: true },
  disableTitle: { type: Boolean, required: false, default: false },
})

const emit = defineEmits(['save', 'cancel'])
</script>
<template>
  <div class="inline flex gap-2">
    <button class="btn save" @click.prevent="emit('save')">
      <CheckCircleIcon class="icon" />
      <template v-if="!disableTitle">{{ props.title }}</template>
    </button>
    <button class="btn cancel" @click.prevent="emit('cancel')" v-if="!props.hideCancel">
      <XCircleIcon class="icon" />
      {{ props.cancel }}
    </button>
  </div>
</template>
